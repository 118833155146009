.table-enter {
  opacity: 0;
}

.table-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.table-exit {
  opacity: 1;
}

.table-exit-active {
  opacity: 0;
}

.arrow-transition {
  transition: transform 0.5s ease;
}

.arrow-rotated {
  transform: rotate(90deg);
}
